import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import CloudflareImage from "../../components/reusable-components/cloudflare-image"
import Carousel from "react-elastic-carousel"

import Layout from "../../components/theme/layout"
import SEO from "../../components/theme/header/seo"
import FlexSection from "../../components/reusable-components/layout/flex-section"
import ProductCarousel from "../../components/products/product-carousel"

import CarouselArrow from "../../components/reusable-components/layout/carousel-arrow"
import { analyticsViewItemList } from "../../services/google-analytics"

import { getProperty } from "../../lib/utils"

const ProjectViewPage = ({ pageContext, data, location }) => {
  const featuredProductsHeading = getProperty(
    pageContext,
    ["featuredProductsHeading"],
    ""
  )
  const featuredProductsSubHeading = getProperty(
    pageContext,
    ["featuredProductsSubHeading"],
    ""
  )
  const category = getProperty(pageContext, ["projectCategory"], "")
  const title = getProperty(pageContext, ["projectTitle"], "")
  const description = getProperty(pageContext, ["projectFullDescription"], "")
  const galleryImages = getProperty(data, ["allWpMediaItem", "nodes"], [])
  const featuredProducts = getProperty(
    data,
    ["allWpSimpleProduct", "nodes"],
    []
  )
  analyticsViewItemList(featuredProducts, "projects_page")

  return (
    <Layout pathContext={pageContext} location={location}>
      <SEO title={title} location={location} yoast={{}} />
      <StyledH2 dangerouslySetInnerHTML={{ __html: category }} />
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <FlexSection
        direction={"row"}
        justify={"space-between"}
        margin={"2rem auto 4rem"}
      >
        <StyledDiv>{description}</StyledDiv>
        <StyledCarousel
          disableArrowsOnEnd={false}
          pagination={false}
          renderArrow={CarouselArrow}
        >
          {galleryImages.map((img, imgIndex) => (
            <StyledImg
              key={imgIndex}
              objectPosition="0 50%"
              objectFit="cover"
              src={getProperty(img, ["sourceUrl"], "")}
            />
          ))}
        </StyledCarousel>
      </FlexSection>
      {featuredProducts.length < 1 ? null : (
        <>
          <StyledH4
            dangerouslySetInnerHTML={{ __html: featuredProductsHeading }}
          />
          <StyledP
            dangerouslySetInnerHTML={{ __html: featuredProductsSubHeading }}
          />
          <ProductCarousel
            className="page-fullwidth-section"
            products={featuredProducts}
          />
        </>
      )}
    </Layout>
  )
}

export default ProjectViewPage

// ===============
//     STYLES
// ===============
const StyledH2 = styled.h2`
  margin-top: 1rem;
  font-size: 1.75rem;
  font-weight: normal;
  text-transform: uppercase;
`
const StyledH4 = styled.h4`
  text-align: center;
`
const StyledP = styled.p`
  text-align: center;
  margin: 0 0 50px;
`
const StyledDiv = styled.div`
  padding-right: 60px;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    padding-right: 0;
    padding-bottom: 60px;
  }
`
const StyledCarousel = styled(Carousel)`
  .rec-carousel {
    width: 630px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      width: 100%;
    }
  }
  .rec-carousel-wrapper {
    position: relative;
  }
  .rec-arrow {
    position: absolute;
    z-index: 1;
    top: 27.25rem;
    height: 50px;
    width: 50px;
    padding: 0;
    color: ${({ theme }) => theme.colors.black1};
    background-color: white;
    box-shadow: none;
    border: none;
    border-radius: 50%;
    img {
      margin-bottom: 0;
    }
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      top: auto;
      bottom: 4.75rem;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      bottom: 4rem;
    }
  }
  .rec-arrow:hover:enabled {
    line,
    path {
      stroke: white;
    }
    background-color: ${({ theme }) => theme.colors.grey};
    box-shadow: none;
  }
  .rec-arrow:focus:enabled {
    line,
    path {
      stroke: white;
    }
    background-color: ${({ theme }) => theme.colors.grey};
    box-shadow: none;
  }
  .rec-arrow-left {
    right: 5.375rem;
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      right: calc(50vw - 15rem);
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      left: 10vw;
      right: auto;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      left: 12vw;
    }
  }
  .rec-arrow-right {
    transform: scaleX(-1);
    right: 1.5rem;
    @media (max-width: ${({ theme }) => theme.breakMedium}) {
      right: calc(50vw - 18.5rem);
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      right: 10vw;
    }
    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      right: 12vw;
    }
  }
`
const StyledImg = styled(CloudflareImage)`
  height: 500px;
  width: 630px; //48.3%;
  align-self: stretch;
  img {
    left: unset;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin-bottom: 3rem;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
    height: auto;
  }
`
// ===============
//      QUERY
// ===============
export const projectViewPageQueryData = graphql`
  query GET_PROJECT_VIEW_PAGE_QUERY_DATA(
    $galleryImageIds: [Int]
    $featuredProductSlugs: [String]
  ) {
    allWpMediaItem(filter: { databaseId: { in: $galleryImageIds } }) {
      nodes {
        sourceUrl
      }
    }
    allWpSimpleProduct(filter: { slug: { in: $featuredProductSlugs } }) {
      nodes {
        name
        shortDescription
        price
        regularPrice
        onSale
        averageRating
        sku
        slug
        categories: productCategories {
          nodes {
            slug
            databaseId
          }
        }
        image {
          sourceUrl
        }
        acf_product_information {
          productHoverImage {
            sourceUrl
          }
        }
        productCategories {
          nodes {
            slug
          }
        }
        acf_product_tile_add_ons {
          squareMetresOfTilesPerBox
        }
      }
    }
  }
`
